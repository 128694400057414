@import "animate.css";

.titre {
  text-align: center;
  color: cornflowerblue;
}

.card {
  margin-top: 10px;
  padding: 5px;
  text-align: center;
}

.card img {
  margin: 5px auto 5px auto;
  width: 80px;
  height: 80px;
}

.cardConnaisance {
  margin-top: 10px;
}

.menu {
  margin: 2px auto 2px auto;
}

.menu a:hover {
  color: #282c34;
}

.cardConnaisance img {
  width: 80px;
  height: 80px;
  border-radius: 10px;
}

.cardReference img {
  width: 100px;
  height: 100px;
}

.header {
  background-image: url(./assets/image/fond.png);
  height: 300px;
}

.information {
  padding-top: 80px;
  text-align: center;
  color: #ffff;
}

.information img {
  width: 100px;
  height: 100px;
  border-radius: 5px;
}

/*  Style footer */
.footer {
  margin-top: 20px;
  padding: 20px;
  background: rgb(107, 107, 228);
  text-align: right;
}

.footer .signature {
  color: white;
}

/* End Style footer */
